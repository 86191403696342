<template>
	<div>
		<div class="content">
			<div class="arrangement">
				<div class="lun" v-if="cards && cards.length > 0">
					<carousel :imgList="cards" @getclick="jump" :type="6"></carousel>
				</div>

				<div class="block_8 flex-col" v-if="allData && allData[0] && allData[0].length > 0">
					<div class="group_8 flex-row">
						<div v-for="(item,index) in allData[0]" :key="index" v-if="index<5"
							:class="navIndex == index + 1 ? 'text-wrapper_2 flex-col nav-wra':'text-wrapper_2 flex-col'"
							@mouseenter="getnav(index + 1,item)" @click="doubleclick(item)">
							<span class="text_7">{{item.name}}</span>
						</div>
					</div>
					<div class="image_8"></div>
					<div v-for="(item,index) in articles" v-if="index<8 && allData[0][navIndex - 1].showType == 0"
						:key="index" @click="jump(item.id)">
						<div class="box_31 flex-row" style="display: flex;justify-content: space-between;">
							<div style="display: flex;">
								<img class="thumbnail_8" referrerpolicy="no-referrer"
									src="../assets/image/titleicon.png" />
								<span class="text_20" style="color: red;width: 18PX;" v-if="item.isTop == 1">顶</span>
								<el-tooltip class="item" effect="dark" :content="item.title" placement="top-start">
									<span class="text_20" style="width: 370PX"><a>{{item.title}}</a></span>
								</el-tooltip>
							</div>
							<span class="text_21">[{{item.publishTime | format}}]</span>
						</div>
						<div class="image_9"></div>
					</div>
					<div v-for="(item,index) in allData[0][navIndex - 1].columns"
						v-if="index<7 && allData[0][navIndex - 1].showType == 1" :key="index" @click="jump(item.id)">
						<div class="box_31 flex-row" style="display: flex;justify-content: space-between;">
							<div style="display: flex;">
								<img class="thumbnail_8" referrerpolicy="no-referrer"
									src="../assets/image/titleicon.png" />
								<el-tooltip class="item" effect="dark" :content="item.name" placement="top-start">
									<span class="text_20"><a>{{item.name}}</a></span>
								</el-tooltip>
							</div>
							<span class="text_21">{{item.createTime | format}}</span>
						</div>
						<div class="image_9"></div>
					</div>
					<div v-for="(item,index) in allData[0][navIndex - 1].columns"
						v-if="index<7 && allData[0][navIndex - 1].showType == 2" :key="index" @click="jump(item.id)">
						<div class="box_31 flex-row">
							<img class="thumbnail_8" referrerpolicy="no-referrer" src="../assets/image/titleicon.png" />
							<el-tooltip class="item" effect="dark" :content="item.name" placement="top-start">
								<span class="text_20"><a>{{item.name}}</a></span>
							</el-tooltip>
						</div>
						<div class="image_9"></div>
					</div>
				</div>
			</div>
		</div>

		<div class="advertisement-box" v-if="adv1.length > 0 && adv1[0]">
			<!-- <img class="advertisement" :src="adv1[0].imgUrl" alt="" @click="navTo(adv1[0])"> -->
			<carousel :imgList="adv1" width="1200" height="120PX" :type="3" @getclickm="getclickm"></carousel>
		</div>

		<div class="game-box">
			<div class="theleft-box">
				<div class="theleft">
					<img src="../assets/image/logo2.png" alt="">
					<span>国际赛事赛历</span>
				</div>
				<div class="event-box">
					<el-carousel trigger="click" height="505PX" :interval="5000"
						v-if="allData && allData[1] && allData[1].length > 0 ">
						<el-carousel-item v-for="(item,index) in allData[1][0].articles" :key="index" v-if="index < 5">
							<img :src="item.imgUrl" alt="" style="overflow-y: auto;height: 505PX;"
								@click="jump(item.id)" v-if="item.imgUrl">
							<img src="../assets/image/placeholder.png" alt="" style="overflow-y: auto;height: 505PX;"
								v-else>
						</el-carousel-item>
					</el-carousel>

					<!-- <img :src="adv2[0].imgUrl" alt="" style="width: 100%;height: 100%;"> -->
				</div>
			</div>

			<div class="therightside-box">
				<div class="therightside">
					<img src="../assets/image/logo2.png" alt="">
					<span>精彩赛事推荐</span>
				</div>
				<div class="Carousel">
					<el-carousel trigger="click" height="168PX" arrow="never" :interval="5000">
						<el-carousel-item v-for="(item,index) in this.wonderfulMatchlist" :key="index" v-if="index < 6">
							<div class="carbox" @click="getdetails(item.id,item)">
								<h3 class="title">{{ item.matchName }}</h3>
								<h3 class="time">比赛时间：{{ item.matchDateStart }} - {{ item.matchDateEnd }}</h3>
								<h3 class="addres">比赛地点：{{ item.matchAddress }}</h3>
							</div>
						</el-carousel-item>
					</el-carousel>
				</div>
				<div class="calendar">
					<div class="text">赛事日历</div>
					<div class="date" style="display: flex;">
						<div style="width: 10PX;height: 10PX;cursor: pointer;" @click="reduce">
							<img src="../assets/eventtheme/theleftside.png" alt="">
						</div>
						<div style="margin: 2PX 15PX;">{{year + '年' + month + '月'}}</div>
						<div style="width: 10PX;height: 10PX;cursor: pointer;" @click="add">
							<img src="../assets/eventtheme/therightside.png" alt="">
						</div>
					</div>
				</div>
				<div class="pecific" v-if="mchList && mchList.length > 0 ">
					<div class="specific-box" v-for="(item,index) in mchList" :key="index">
						<div class="specific-content" :style="{'background': (index % 2 == 0) ? ' ':'#fff',}"
							@click="getdetails(item.id,item)">
							<span class="title">{{item.matchName}}</span>
							<span class="date">比赛时间：{{item.matchDateStart}} 至 {{item.matchDateEnd}}</span>
							<span class="addse">比赛地点：{{item.matchAddressStadium}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="moment" v-if="homeImageList && homeImageList.length > 0">
			<div>
				<img src="../assets/image/logo2.png" alt="">
				<div>精彩瞬间</div>
			</div>
			<div class="gd" @click="getjump">
				更多
				<img src="../assets/eventtheme/therightside.png" alt="">
			</div>
		</div>

		<div class="wonderful" v-if="homeImageList && homeImageList.length > 0">
			<img v-for="(item,index) in homeImageList" :key="index" v-if="index<6" :src="item.imgUrl" alt=""
				@click="getdetailss(item.matchId,item,'home')"
				style="margin-bottom: 0;margin-top: 8PX;margin-bottom: 10PX;">
		</div>

		<div class="brand" v-if="brandevents && brandevents.length > 0">
			<img src="../assets/image/logo2.png" alt="">
			<span>北京竞技场</span>
		</div>

		<div class="wonderful brandful" v-if="brandevents && brandevents.length > 0">
			<img v-for="(item,index) in brandevents" :key="index" v-if="index<5" :src="item.img_url" alt=""
				@click="toggleSubMenu1(item)">
		</div>

		<div class="link" v-if="fLinks.length>0">
			<img src="../assets/image/logo3.png" alt="">
			<span style="margin-right: 120PX;">官网链接</span>
			<div class="link-box" v-for="item in fLinks">
				<span @click="toggleSubMenu(item.pathUrl)">{{item.name}}</span>
			</div>
		</div>

	</div>
</template>

<script>
	import carousel from '@/components/carousel.vue';
	export default {
		name: "home",
		components: {
			carousel
		},
		data() {
			return {
				adv1: [],
				adv2: [],
				navIndex: 1,
				allData: [],
				articles: [],
				cards: [],
				fLinks: [],
				mchList: [],
				brandevents: [],
				columnId: '',
				columnPid: '',
				homeImageList: [],
				year: '',
				month: '',
				wonderfulMatchlist: [],
				pattern:'<br>'
			}
		},
		computed: {},
		created() {
			const timeOne = new Date()
			const year = timeOne.getFullYear()
			let month = timeOne.getMonth() + 1
			let day = timeOne.getDate()
			month = month < 10 ? '0' + month : month
			day = day < 10 ? '0' + day : day
			const NOW_MONTHS_AGO = `${year}年${month}月`
			this.year = year
			this.month = month
			this.$store.commit("EXIT_STATIONID", '1005012')
			this.getIndexImgList()
			this.getBanner()
			this.getIndexColumn()
			this.matchList()
			this.getNav()
			this.getNavList()
			// this.homeImage()
			this.getFriendLink()
			this.getWonderfulMatch()
		},
		filters: {
			format(x) {
				if (x != null && x != '') {
					return x.split(" ")[0]
				}
			}
		},
		mounted() {

		},
		methods: {
			getIndexImgList() {
				this.$api.getIndexImgList().then(res => {
					this.cards = res.data.data
				})
			},
			getnav(e, item) {
				this.navIndex = e
				this.articles = item.articles
				this.articles.forEach(item=>{
					item.title= item.title.replace(/<br>/g, ' ')
				})
			},
			getNavList() {
				this.$api.getNav().then(res => {
					res.data.data.forEach(item => {
						if (item.children.length > 0) {
							item.children.forEach(res => {
								if (res.name == '登记/查询') {
									this.columnId = res.id
									this.columnPid = res.parentId
								}
							})
						}
					})
				})
			},
			getIndexColumn() {
				this.$api.getIndexColumn(this.$store.state.stationId).then(res => {
					this.allData = res.data.data
					this.articles = res.data.data[0][0].articles
					this.$api.homeImage(res.data.data[2][0].id).then(res => {
						this.homeImageList = res.data.data
					})
					this.articles.forEach(item=>{
						item.title= item.title.replace(/<br>/g, ' ')
					})
				})
			},
			//获取轮播数据
			getBanner() {
				this.$api.getTopBanner({
					type: "1"
				}).then(res => {
					res.data.data.forEach(item => {
						this.adv1.push(item)
					})
				})
			},
			navTo(item) {
				if (item.skipUrl.includes('http')) {
					window.open(item.skipUrl);
				} else {
					this.$router.push(item.skipUrl)
				}
			},
			getFriendLink() {
				this.$api.getFriendLink({type:0}).then(res => {
					this.fLinks = res.data.data
				})
			},
			add() {
				const currentYear = parseInt(this.year);
				const currentMonth = parseInt(this.month);
				const currentDate = new Date(currentYear, currentMonth - 1, 1);
				currentDate.setMonth(currentDate.getMonth() + 1);
				this.year = currentDate.getFullYear().toString();
				this.month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
				this.matchList()
			},
			reduce() {
				const currentYear = parseInt(this.year);
				const currentMonth = parseInt(this.month);
				const currentDate = new Date(currentYear, currentMonth - 1, 1);
				currentDate.setMonth(currentDate.getMonth() - 1);
				this.year = currentDate.getFullYear().toString();
				this.month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
				this.matchList()
			},
			matchList() {
				let data = {
					pageNo: 1,
					pageSize: 10000,
					matchYear: this.year,
					matchMonth: this.month,
					orderCondition:'asc'
				}
				this.$api.getMatchBasicInfo(data).then(res => {
					res.data.data.result.records.forEach(item => {
						item.matchAddressStadium = item.addresses.map(item => {
							return item.matchAddressStadium
						}).join('、')
					})
					this.mchList = res.data.data.result.records
				})
			},
			jump(id) {
				let routeData = this.$router.resolve({
					path: '/articleDetails',
					query: {
						id: id,
						refresh: new Date().getTime()
					}
				});
				window.open(routeData.href, '_blank');
			},
			getclickm(row) {
				if (row.skipUrl == '111') {
					if (this.$store.state.token == '') {
						this.$store.commit("EXIT_TOKEN")
						this.$router.push({
							path: '/login',
							query: {
								person: false,
								grp: true,
								refresh: new Date().getTime()
							}
						})
						return
					}
					
					if (this.$store.state.userType == 'user_type_01') {
						this.$confirm('当前账号为个人账号，是否切换成团体账号', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							this.$store.commit("EXIT_TOKEN")
							this.$router.push({
								path: '/login',
								query: {
									person: false,
									grp: true,
									refresh: new Date().getTime()
								}
							})
						}).catch(() => {});
						return
					}
					
					this.$api.getTimeStamp().then(ess => {
						let data = {
							accessToken: this.$store.state.token,
							redirectURL: '/sportMatch/SportMatchList',
							timestampe: ess.data.data.timestamp
						}
						this.$api.getCode(data).then(err => {
							let url = process.env.VUE_APP_BASE_API_ADMIN + '/tokenLogin?code=' + err.data
								.data.result.code
							window.open(url, '_self');
						})
					})
					
				} else if (row.skipUrl && row.skipUrl != '111') {
					window.open(row.skipUrl, '_blank');
				}
			},
			getdetails(id, row, type) {
				if (id == null || id == '' || id == undefined) return
				this.$router.push({
					path: '/details',
					query: {
						id: id,
						refresh: new Date().getTime(),
						type: 1,
						columnId: this.columnId,
						columnPid: this.columnPid,
						type: type
					}
				});
			},
			getdetailss(id, row, type) {
				if (this.$store.state.token == '') {
					this.$confirm('请登录后查看更多信息', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.$store.commit("EXIT_TOKEN")
						this.$router.push({
							path: '/login',
							query: {
								person: true,
								grp: false,
								refresh: new Date().getTime()
							}
						})
					}).catch(() => { });
					return
				}
				if (id == null || id == '' || id == undefined) return
				this.$router.push({
					path: '/details',
					query: {
						id: id,
						refresh: new Date().getTime(),
						type: 1,
						columnId: this.columnId,
						columnPid: this.columnPid,
						type: type
					}
				});
			},
			getjump(){
				let url = window.location.origin + window.location.pathname + 'notice?pid=847&id=728'
				window.open(url, '_self');
			},
			getNav() {
				this.$api.bjSport(744,1).then(res => {
					this.brandevents = res.data.data
				})
			},
			toggleSubMenu(url) {
				window.open(url, '_blank');
			},
			toggleSubMenu1(item) {
				if (item.bind_theme == 0) {
					window.open(item.link_address, '_blank');
				} else {
					this.$router.push({
						path: item.link_address,
						query: {
							logo: item.img_url,
							title: item.name,
							refresh: new Date().getTime()
						},
					})
				}
			},
			doubleclick(e) {
				this.$router.push({
					path: '/notice',
					query: {
						pid: e.parentId,
						id: e.id,
						template: e.template,
						refresh: new Date().getTime()
					}
				})
			},
			getWonderfulMatch() {
				this.$api.getWonderfulMatch().then(res => {
					this.wonderfulMatchlist = res.data.data.result
				})
			}
		}
	}
</script>
<style lang="scss" scoped>
	* {
		margin: 0;
		padding: 0;
	}

	.nav-wra {
		background: #BD1608 !important;
		color: #FFFFFF !important;
	}

	.content {
		width: 1200PX;
		margin: 0 auto;
	}

	.arrangement {
		display: flex;
		justify-content: space-between;
		margin-top: 9PX;
	}

	.lun {
		width: 652PX;
		height: 380PX;
	}

	.lun .main {
		position: relative;
		overflow: hidden;
	}

	.lun .lun_bo {
		overflow: hidden;
		position: absolute;
		height: 380PX;
		width: 652PX;
	}

	.lun .lun_bo:hover {
		cursor: pointer;
	}

	.lun .lun_bo img {
		display: block;
		float: left;
		height: 380PX;
		width: 652PX;
	}

	.lun .btn_demo {
		width: 652PX;
		height: 70PX;
		background: #07549C;
		opacity: 0.8;
		position: absolute;
		bottom: 0;
		left: 0;
	}

	.lun .btn_disc {
		display: flex;
		justify-content: center;
	}

	.lun .desc {
		color: #fff;
		text-align: center;
		height: 19PX;
		font-size: 18PX;
		margin: 17PX auto 0 auto;
	}

	.lun a {
		width: 13PX;
		height: 13PX;
		background: #FFFFFF;
		border-radius: 50%;
		display: flex;
		margin-right: 10PX;
		cursor: pointer;
		margin-top: 13PX;
	}

	.lun a:hover {
		cursor: pointer;
	}

	.lun .direct {
		background: transparent;
		position: absolute;
		top: 200PX;
		height: 30PX;
		line-height: 25PX;
		text-align: center;
		font-size: 40PX;
		padding: 3PX;
		border: 4PX solid #fff;
		color: #fff;
		font-weight: 700;
		display: none;
	}

	.lun .left {
		left: 70PX;
	}

	.lun .right {
		right: 70PX;
	}

	.lun .direct:hover {
		cursor: pointer;
	}

	.block_8 {
		width: 532PX;
		height: 379PX;
	}

	.group_8 {
		background-color: #fff;
		// width: 400PX;
		height: 42PX;
		display: flex;
		justify-content: flex-center;
	}

	.text-wrapper_2 {
		height: 42PX;
		margin-left: 1PX;
		width: 100%;
		line-height: 42PX;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1PX solid #D0D0D0;
		cursor: pointer;
	}

	.text_7 {
		height: 17PX;
		overflow-wrap: break-word;
		font-size: 16PX;
		font-family: MicrosoftYaHei-Bold;
		font-weight: bold;
		text-align: left;
		white-space: nowrap;
		line-height: 16PX;
	}

	.group_9 {
		background-color: rgba(208, 208, 208, 1);
		width: 528PX;
		height: 1PX;
	}

	.box_31 {
		width: 523PX;
		height: 19PX;
		margin-top: 10PX;
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	.thumbnail_8 {
		width: 12PX;
		height: 12PX;
	}

	.text_20 {
		width: 395PX;
		height: 17PX;
		overflow-wrap: break-word;
		color: rgba(51, 51, 51, 1);
		font-size: 15PX;
		font-family: MicrosoftYaHei;
		font-weight: 400;
		text-align: left;
		white-space: nowrap;
		line-height: 16PX;
		margin: 1PX 0 0 13PX;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
	}

	.text_20:hover a {
		color: #408ED6;
	}

	.image_9 {
		border: 1PX dashed #CCD5D5;
		margin-top: 11PX;
	}

	.image_8 {
		background-color: rgba(208, 208, 208, 1);
		height: 1PX;
	}

	.advertisement-box {
		width: 1200PX;
		height: 120PX;
		margin: 16PX auto 0 auto;
	}

	.advertisement {
		width: 1200PX;
		height: 120PX;
	}

	.game-box {
		width: 1200PX;
		margin: 0 auto;
		margin-top: 11PX;
		display: flex;
		justify-content: space-between;
	}

	.theleft-box {
		width: 580PX;
	}

	.theleft {
		width: 580PX;
		height: 50PX;
		background: #164B92;
		display: flex;
		align-items: center;
	}

	.theleft img {
		width: 34PX;
		height: 32PX;
		margin-left: 8PX;
	}

	.theleft span {
		font-size: 18PX;
		font-weight: bold;
		color: #FFFFFF;
		margin-left: 9PX;
	}

	.event-box {
		margin-top: 10PX;
		height: 505PX;
		overflow-y: auto;
	}

	.event-box .event-box-img::-webkit-scrollbar {
		width: 15PX;
		/* position: absolute; */
	}

	.event-box .event-box-img::-webkit-scrollbar-thumb {
		width: 7PX;
		background: #FFFFFF;
		border-radius: 4PX;
	}

	.event-box .event-box-img::-webkit-scrollbar-track {
		background: #ECEDEF;
	}

	.event-name {
		margin-top: 26PX;
		width: 306PX;
		height: 59PX;
		background: url('../assets/image/theleft.png');
		margin-left: 15PX;
	}

	.event-name img {
		position: absolute;
		margin-left: 15PX;
	}

	.event-name span {
		font-size: 30PX;
		font-family: Source Han Sans CN;
		font-weight: bold;
		color: #FFFDFD;
		line-height: 32PX;
		-webkit-text-stroke: 2PX #000000;
		text-stroke: 2PX #000000;
		position: relative;
		z-index: 99;
		display: flex;
		justify-content: center;
		top: 11PX;
	}

	.specific {
		display: flex;
		margin-top: 25PX;
		font-size: 15PX;
	}

	.specificBottm {
		margin-bottom: 25PX;
	}

	.specific .date {
		margin-left: 15PX;
		width: 125PX;
	}

	.specific .name {
		width: 250PX;
	}

	.specific .addes {
		width: 200PX;
	}

	.therightside-box {
		width: 600PX;
	}

	.therightside {
		height: 50PX;
		background: #BD1608;
		display: flex;
		align-items: center;
	}

	.therightside img {
		width: 34PX;
		height: 32PX;
		margin-left: 8PX;
	}

	.therightside span {
		font-size: 18PX;
		font-weight: bold;
		color: #FFFFFF;
		margin-left: 9PX;
	}

	.calendar {
		width: 600PX;
		height: 50PX;
		background: #164B92;
		margin-top: 8PX;
		display: flex;
		align-items: center;
	}

	.calendar .text {
		height: 25PX;
		font-size: 18PX;
		font-weight: 400;
		color: #FFFFFF;
		margin-left: 254PX;
	}

	.calendar .date {
		font-size: 16PX;
		font-weight: 400;
		color: #fff;
		margin-left: 120PX;
	}

	.pecific {
		height: 281PX;
		overflow-y: auto;
		cursor: pointer;
	}

	.pecific::-webkit-scrollbar {
		width: 15PX;
		/* position: absolute; */
	}

	.pecific::-webkit-scrollbar-thumb {
		width: 7PX;
		background: #FFFFFF;
		border-radius: 4PX;
	}

	.pecific::-webkit-scrollbar-track {
		background: #ECEDEF;
	}

	.specific-box {
		height: 84PX;
		background: #E2E2E2;
		margin-top: 10PX;
	}

	.specific-content {
		padding: 12PX 0 11PX 49PX;
		display: flex;
		flex-direction: column;
	}

	.specific-content .title {
		font-size: 16PX;
		font-family: MicrosoftYaHei;
		font-weight: bold;
		color: #333333;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
	}

	.specific-content .date {
		font-size: 13PX;
		margin-top: 4PX;
	}

	.specific-content .addse {
		font-size: 13PX;
		margin-top: 4PX;
		width: 520PX;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
	}

	.moment {
		width: 1200PX;
		height: 50PX;
		background: #164B92;
		margin-top: 10PX;
		margin: 10PX auto;
		display: flex;
		align-items: center;
    	justify-content: space-between;
	}

	.moment img {
		width: 34PX;
		height: 32PX;
		margin-left: 8PX;
	}

	.moment div {
		font-size: 18PX;
		font-weight: bold;
		color: #FFFFFF;
		margin-left: 10PX;
		display: flex;
		align-items: center;
	}
	.moment .gd{
		position: relative;
		right: 15PX;
		cursor: pointer;
		img{
			width: 10PX;
			height: 16PX;
			cursor: pointer;
		}
	}

	.wonderful {
		width: 1200PX;
		display: flex;
		justify-content: flex-start;
		margin: 0 auto;
		cursor: pointer;

	}

	.wonderful img {
		width: 190PX;
		height: 127PX;
		border-radius: 10PX;
		margin-bottom: 44PX;
		margin-right: 12PX;
	}

	.wonderful img:nth-child(6n) {
		margin-right: 0;
	}

	.wonderful img:hover {
		transform: scale(1.1, 1.1);
	}

	.brand {
		width: 1200PX;
		height: 50PX;
		background: #BD1608;
		margin: 10PX auto;
		display: flex;
		align-items: center;
	}

	.brand img {
		width: 34PX;
		height: 32PX;
		margin-left: 8PX;
	}

	.brand span {
		font-size: 18PX;
		font-weight: bold;
		color: #FFFFFF;
		margin-left: 10PX;
	}

	.brandful img {
		border-radius: 0;
		margin-top: 12PX;
		margin-bottom: 33PX;
		margin-right: 64PX;
	}

	.link {
		width: 1200PX;
		height: 50PX;
		background: #DEDEDE;
		margin: 0PX auto;
		margin-bottom: 33PX;
		display: flex;
		align-items: center;
	}

	.link img {
		width: 34PX;
		height: 32PX;
		margin-left: 8PX;
	}

	.link span {
		font-size: 18PX;
		font-weight: bold;
		color: #CC0000;
		margin-left: 10PX;
	}

	.link-box {
		display: flex;
	}

	.link-box span {
		font-size: 16PX;
		font-weight: 400;
		color: #333333;
		margin-right: 66PX;
		cursor: pointer;
	}

	.Carousel {
		margin-top: 8PX;
		height: 168PX;
		background: #98bbe2;
		color: #fff;

		.carbox {
			margin-top: 35PX;
			margin-left: 49PX;

			.title {
				font-size: 16PX;
				font-family: MicrosoftYaHei;
				font-weight: bold;

				background-color: #ffffff;
				color: #2E97D8;
				height: 40PX;
				line-height: 40PX;
				border-radius: 20PX;
				padding-left: 20PX;
				width: 360PX;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			.time {
				margin-top: 10PX;
			}

			.addres {
				margin-top: 10PX;
			}
		}
	}
</style>